import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/authStore';
import socket from '@/emitters/socketBus.js';
import moment from 'moment';
export const useOrderStore = defineStore("order", {
    state: () => {
        return {
            orders: [],
            cachedOrders: (localStorage.getItem('cached_orders')) ? JSON.parse(localStorage.getItem('cached_orders')) : [],
        }
    },
    getters: {
        allOrders(state) {
            let allOrders = [
                ...state.orders,
                ...state.cachedOrders,
            ]

            // Filter out unwanted orders
            allOrders = allOrders.filter(e => {
                if(e.order.order_details.status_numeric == 4) {
                    return false;
                }
                return e;
            });

            // Sort by status
            allOrders = allOrders.sort((a,b) => {
                return a.order.order_details.status_numeric - b.order.order_details.status_numeric;
            });

            return allOrders
        },
        hasOrders(state) {
            return (state.allOrders.length > 0);
            /*return (state.orders.length > 0 || state.cachedOrders.length > 0);*/
        },
        processingOrders(state) {
            let orders = state.orders.filter(e => {
                return (e.order.order_details.status_numeric == 1);
            });
            return orders.length;
        },
    },
    actions: {
        async resubmitOrder(order) {
            let response = await axios.post('webapp/resubmit-order', {
                'order_id': order.order.order_id,
            });
            if (response.data.status == true) {

                this.removeCachedOrder(order);

                order.order.order_details.status_numeric = 1;
                order.order.order_details.order_date = this.adjustClientTime(moment(new Date()));
                if(!order.retry_count) {
                    order.retry_count = 0;
                }
                order.retry_count++;

                this.orders.push(order);

                /*setTimeout(() => {
                    this.fetchOrders();
                },1000);*/
            } else {
                throw "Eroare plasare comandă";
            }

        },
        async cacheOrder(order) {
            console.log('caching order', order);
            // Cache item
            this.cachedOrders.push(order);
            let storageOrders = JSON.stringify(this.cachedOrders);
            localStorage.setItem('cached_orders', storageOrders);
            // Remove initial item from store
            let orderIndex = this.orders.findIndex(e => {
                return (e.order.order_id == order.order.order_id);
            });
            this.orders.splice(orderIndex, 1);
        },
        async removeCachedOrder(order) {
            let cachedOrderIndex = this.cachedOrders.findIndex(e => {
                return (e.order.order_id == order.order.order_id);
            });
            this.cachedOrders.splice(cachedOrderIndex, 1);
            let storageOrders = JSON.stringify(this.cachedOrders);
            localStorage.setItem('cached_orders', storageOrders);
        },
        async handleStatusChange(order) {
            console.log('instore order changed', order);

            // Handle special cases
            if (order.status_numeric == -4) {
                let storeOrder = this.orders.find(e => {
                    return (e.order.order_id == order.id);
                })
                storeOrder.order.order_details.status = order.status;
                storeOrder.order.order_details.status_numeric = order.status_numeric;

                this.cacheOrder(storeOrder);
                return;
            }

            // Grab orders from the db
            setTimeout(() => {
                this.fetchOrders();
            }, 1000);
        },
        async receiveMessage(message) {
            let storeOrder = this.orders.find(e => {
                return (e.order.order_id == message.order_id);
            });
            if (storeOrder) {
                storeOrder.messages.push(message);
            } else {
                console.warn('unidentified message', messages);
            }
        },
        async sendMessage(order, message) {

            let authStore = useAuthStore();
            let data = {
                'send_dts': Date.now(),
                'in_dts': Date.now(),
                'sender_id': authStore.auth_user.user_id,
                'order_id': order.order.order_id,
                'message': message,
                'message_body': message,
            }

            let response = await axios.post('clients/send-driver-message', data);
            if (response.data.status == true) {
                let storeOrder = this.orders.find(e => {
                    return (e.order.order_id == order.order.order_id);
                });

                if (storeOrder) {
                    storeOrder.messages.push(data);
                    return data;
                } else {
                    throw "Eroare trimitere mesaj";
                }

            } else {
                throw "Eroare trimitere mesaj";
            }

        },
        async cancelOrder(order) {
            let data = {
                'order_id': order.order.order_id,
            }
            let response = await axios.post('clients/cancel-order', data);
            if (response.data.status === true) {
                await this.fetchOrders();
            } else {
                throw "Eroare raspuns comandă";
            }
        },
        async acceptDriver(order, offer) {
            let data = {
                'order_id': order.order.order_id,
                'driver_id': offer.driver.driver_id,
            }
            let response = await axios.post('clients/accept-driver', data);
            if (response.data.status === true) {
                await this.fetchOrders();
            } else {
                throw "Eroare raspuns comandă";
            }
        },
        async create(order) {
            let response = await axios.post('clients/place-order', order);
            if (response.data.status == true) {
                let newOrder = {
                    order: response.data.data,
                    offers: [],
                    messages: [],
                }
                newOrder.order.order_details.status_numeric = 1;
                if(newOrder.order.destination_address_details) {
                    newOrder.order.dst_address_details = newOrder.order.destination_address_details;
                }
                this.orders.push(newOrder);
                //await this.fetchOrders();
            } else {
                throw "Eroare plasare comandă";
            }
        },
        adjustClientTime(clientTime) {
            let authStore = useAuthStore();
            let timeDifference = authStore.timeDifference;
            clientTime.subtract(timeDifference, 'seconds');
            return clientTime;
        },
        async addOffer(offer) {
            let storeOrder = this.orders.find(e => {
                return (e.order.order_id == offer.id);
            });
            if (storeOrder) {
                offer.driver_response_time = this.adjustClientTime(moment(new Date()));
                storeOrder.offers.push(offer);
            } else {
                console.warn('unidentified offer', offer);
            }
        },
        async fetchOrders() {
            let authStore = useAuthStore();
            let response = await axios.post('webapp/init-new', authStore.auth_user); // change later to init
            if (response.data.status == true) {
                this.orders = response.data.data;
            } else {
                throw "Eroare incarcare comenzi";
            }
        }
    },
});
