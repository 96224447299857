import {decode as decodePolyline} from "@googlemaps/polyline-codec";

export default {
    // Get order status name by number
    getStatusName(statusNumber) {
        switch (statusNumber) {
            case 1:
                return "Căutare Șofer"
            case 3:
                return "Șofer Acceptat"
            case 4:
                return "În drum spre destinație";
            case 5:
                return "Finalizată";
            case -4:
                return "Neprezentare Client";
            case -5:
                return "Șoferul nu a venit";
            case -6:
                return "Anulată";
            case -21:
                return "Nu am găsit șofer";
            default:
                return "";
        }
    },
    getStatusClass(statusNumber) {
        switch (statusNumber) {
            case 1:
                return "red"
            case 3:
                return "red"
            case 4:
                return "green";
            default:
                return "xxx";
        }
    },
    // Decode google polyline to point array
    decodePolyline(polyString) {
        try {
            let polyArray = decodePolyline(polyString, 5);
            return polyArray.map(e => {
                return {
                    lat: e[0],
                    lng: e[1]
                }
            })
        } catch (error) {
            console.log(error);
            return [];
        }
    },
}
