import {createApp} from 'vue';
import {createPinia} from 'pinia';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faGripVertical, faCircleMinus} from '@fortawesome/free-solid-svg-icons'
import Toaster from '@meforma/vue-toaster';
import router from './router/indexRoutes.js';
import vuetify from './config/vuetifyConfig.js';
import AOS from 'aos'
import 'aos/dist/aos.css'
import './config/globalConfig.js';
import './assets/scss/style.scss';

library.add(faGripVertical, faCircleMinus)

import App from './App.vue';

const pinia = createPinia();
const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);
app.use(pinia)
    .use(router)
    .use(vuetify)
    .use(Toaster, {
        position: 'bottom'
    })
    .use(AOS)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyD6iEN2QKG-T_88pbYLSM65N8Pyc6GvxnU',
            language: 'ro',
            loading: 'async',
            v: 'quarterly',
        },
        autobindAllEvents: false,
    });

app.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

// App debug only
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

app.component('json-dump', VueJsonPretty);
import moment from 'moment';
// Global helpers, "filters"
app.config.globalProperties.$moment = {
    moment: moment,
    format(date, format) {
        return moment(date).lang('ro').format(format);
    },
};

app.mount('#app');
