<template>
    <div class="component select-payment-method">
        <div class="select" @click="handleClick">
            <v-select class="payment-select" v-model="selected" v-if="methods" item-title="info" return-object placeholder="Metodă de plată" :items="methods.methods" hide-details variant="solo" readonly>
                <template v-slot:selection="{ props, item }">
                    <img style="margin-right: 10px;" v-if="item?.raw?.type == 1" src="@/assets/svg/icon_cash.svg" alt="" @click="handleClick">
                    <img style="margin-right: 10px;" v-if="item?.raw?.type == 2" src="@/assets/svg/icon_card.svg" alt="" @click="handleClick">
                    <img style="margin-right: 10px;" v-if="item?.raw?.type == 4" src="@/assets/svg/icon_invoice.svg" alt="" @click="handleClick">
                    <span class="method-name" style="text-transform: capitalize; text-overflow: ellipsis; width: calc(100%); overflow: hidden; white-space: nowrap;" @click="handleClick" >{{ item?.raw?.info.substring(0, 8) }}</span>
                </template>
                <template v-slot:item="{ props, item }">
                    <div class="item" style="padding: 10px 20px; cursor: pointer;" v-bind="props" v-ripple>
                        <img style="margin-right: 10px;" v-if="item?.raw?.type == 1" src="@/assets/svg/icon_cash.svg" alt="">
                        <img style="margin-right: 10px;" v-if="item?.raw?.type == 2" src="@/assets/svg/icon_card.svg" alt="">
                        <img style="margin-right: 10px;" v-if="item?.raw?.type == 4" src="@/assets/svg/icon_invoice.svg" alt="">
                        <span class="method-name" style="vertical-align: top; text-transform: capitalize; white-space: nowrap;"> {{ item?.raw?.info.substring(0, 8) }}</span>
                    </div>
                </template>
            </v-select>
            <v-progress-circular indeterminate v-else></v-progress-circular>
        </div>
        <div class="modals">
            <v-dialog v-model="modal" width="400px" persistent>
                <v-card class="modal-change-payment-method">
                    <v-card-text>
                        <iframe :src="paymentMethodsURL" style="width: 100%; height: 500px; border: 0;">
                        </iframe>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ok" color="primary" block @click="closeModal">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style lang="scss">
.modal-change-payment-method {
    .ok {
        background-color: #f2c83b !important;
        color: #000000 !important;
        font-weight: 600;
    }
}
.payment-select {
    .v-select__selection-text {
        text-transform: capitalize;
    }
}
</style>
<script>
import { useAuthStore } from '@/stores/authStore.js';
import { mapStores } from 'pinia';
import eventBus from '@/emitters/eventBus.js';
export default {
    name: "SelectPaymentMethod",
    data() {
        return {
            methods: null,
            selected: null,
            modal: false,
        }
    },
    watch: {
        'selected': {
            async handler(newValue) {
                try {
                    let response = await axios.post('payments/client/set-default-method', {
                        methodId: this.selected.id,
                    });
                    if (response.data.status != true) {
                        this.$toasted.error('Eroare setare metodă plată');
                    }
                    this.$emit('updated', this.selected);
                } catch (error) {
                    console.log(error);
                    this.$toasted.error('Eroare setare metodă plată');
                }
            }
        }
    },
    computed: {
        ...mapStores(useAuthStore),
        paymentMethodsURL() {
            return `https://api.startaxiapp.com/api/v2/payments/client/webview/default?session_key=${this.authStore.auth_token}&lng=ro&night=0&context=business`
        }
    },
    async mounted() {
        await this.updateDefaultPaymentMethod();

        eventBus.on('triggerPaymentMethodsModal', () => {
                this.modal = true;
            });
    },
    beforeUnmount() {
        eventBus.off('triggerPaymentMethodsModal');
    },
    methods: {
        async closeModal() {
            this.modal = false;
            await this.updateDefaultPaymentMethod();
        },
        async handleClick(event) {
            event.preventDefault();
            this.modal = true;
        },
        async updateDefaultPaymentMethod() {
            await this.getPaymentMethods();
            this.selected = this.methods.methods.find(e => {
                return (e.id === this.methods.default);
            });
        },
        async getPaymentMethods() {
            try {
                let response = await axios.get('payments/client/methods');
                if (response.data.status == true) {
                    this.methods = response.data.data;
                } else {
                    throw "Eroare incarcare metode de plată.";
                }
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare incarcare metode de plată.");
            }

        }
    }
}
</script>
