<template>
  <div class="view app">
    <navbar/>
    <div class="content" v-if='!loading'>
      <router-view/>
    </div>
    <div class="app-loading" v-if="loading">
      <v-progress-circular :size="50" width="6" indeterminate color="primary"/>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/navigation/Navbar/Navbar.vue';
import {useAuthStore} from '@/stores/authStore.js';
import {useOrderStore} from '@/stores/orderStore.js';
import {mapStores} from 'pinia';
import socketBus from '@/emitters/socketBus.js';
import browserDetect from 'browser-detect';

export default {
  name: "AppView",
  components: {
    Navbar,
  },
  data() {
    return {
      socket: null,
      loading: true,
      pingTimer: null,
      reconnectTimer: null,
      retryCount: 0,
      isAlive: true,
    }
  },
  computed: {
    ...mapStores(useAuthStore, useOrderStore),
    isConnected() {
      return this.socket != null;
    }
  },
  async mounted() {
    await this.initUserLocation();
    await this.initDeviceSettings();
    this.reconnect();
  },
  methods: {
    async initDeviceSettings() {
      let browser = await browserDetect();
      let data = {
        reg_id: '',
        lng: 'ro',
        os_type: 5,
        device_type: browser.mobile.toString(),
        device_name: browser.name,
        os_version: browser.os,
        version_code: 200,
        version_name: '2.0',
        device_diag: window.innerHeight + ' x ' + window.innerWidth,
        api_token: this.authStore.token,
      };
      let response = await axios.post("/init/set-device-info", data);
      if (response.data.status !== true) {
        this.$toast.error("Eroare inițializare dispozitiv");
      }
      // Set user local time difference
      // Grab server time from headers after cors fix

      let serverTime = this.$moment.moment(response.headers.date);
      let localTime = this.$moment.moment();
      let timeDifference = this.$moment.moment.duration(localTime.diff(serverTime));
      this.authStore.setTimeDifference(timeDifference);
    },
    async initUserLocation() {
      navigator.geolocation.getCurrentPosition(
          userLocation => {
            this.authStore.setLocation({lat: userLocation.coords.latitude, lng: userLocation.coords.longitude});
            this.loading = false;
          },
          error => {
            this.$toast.error("Accesul la locatie a fost respins");
            console.log(error);
            this.loading = false;
          });
    },
    sendWsMessage(message) {
      console.log('ws message out', message);
      this.socket.send(JSON.stringify(message));
    },
    eventOnline() {
      console.log('Became online')
      this.reconnect();
    },

    eventOffline() {
      console.log('Became offline')
      this.socket?.send('{}');
    },

    closeConnection() {
      clearTimeout(this.reconnectTimer)
      this.reconnectTimer = null;
      clearInterval(this.pingTimer);
      this.pingTimer = null;
      this.socket?.close(1000, "");
      this.socket = null
    },

    reconnect() {
      this.closeConnection();
      this.reconnectTimer = setTimeout(() => {
        if (this.authStore.loggedIn)
          this.initWebSockets();
        else
          this.$router.push('/login')
      }, 300)
    },

    pingSocket() {
      if (!this.isAlive) {
        console.log('ping timeout');
        this.reconnect();
        return;
      }
      this.isAlive = false;
      console.log('ping ' + this.isConnected);
      this.sendWsMessage({event: 'ping'});
    },

    onWsMessage(message) {
      message = JSON.parse(message.data);
      console.log('[' + new Date().toUTCString() + '] ', 'ws message in', message);
      socketBus.emit(message.event, message);
      switch (message.event) {
        case 'pong':
          this.isAlive = true;
          break;
        case 'order-message':
          this.orderStore.receiveMessage(message);
          break;
        case 'order-offer':
          this.orderStore.addOffer(message);
          break;
        case 'order-status-change':
          this.orderStore.handleStatusChange(message);
          break;
        case 'forceDisconnect':
          this.authStore.softLogout();
          this.$router.push('/login');
          break;
      }
    },

    onWsClose(event) {
      if (event.currentTarget === this.socket) {
        console.log('ws closed', event);
        this.reconnect();
      }
    },

    initWebSockets() {
      this.isAlive = true;
      let wsUrl = 'wss://api.startaxiapp.com/ws-clients/?api-token=' + this.authStore.token;
      const ws = new WebSocket(wsUrl);

      ws.onopen = (event) => {
        console.log('ws open', event);
        this.socket = event.currentTarget;
        this.orderStore.fetchOrders();
        this.pingTimer = setInterval(() => {
          this.pingSocket()
        }, 10000);
      };

      ws.onclose = (event) => this.onWsClose(event);
      ws.onerror = (event) => this.onWsClose(event);
      ws.onmessage = (message) => this.onWsMessage(message);
    }
  },
  beforeMount() {
    window.addEventListener('online', this.eventOnline);
    window.addEventListener('offline', this.eventOffline);
    socketBus.on('message', (message) => {
      this.sendWsMessage(message);
    });
  },
  beforeUnmount() {
    window.removeEventListener('online', this.eventOnline);
    window.removeEventListener('offline', this.eventOffline);
    this.closeConnection()
    socketBus.off('message');
  }
}
</script>
