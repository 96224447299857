import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

const theme = {
    themes: {
        light: {
            dark: false,
            colors: {
                primary: '#FFCE09', // #E53935
                secondary: colors.red.lighten4, // #FFCDD2
            }
        },
    },
};

const vuetify = createVuetify({
    components,
    directives,
    theme,
    icons: {
        defaultSet: 'mdi',
    },
});
export default vuetify;