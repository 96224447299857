<template>
    <div class="view login h-100">
        <v-container class="h-100">
            <v-row justify="center" align="center" class="h-100">
                <v-col xs="12" sm="6" md="5" lg="4" xl="3">
                    <div class="card-login" data-aos="fade">
                        <div class="card-body">
                            <h5 class="card-title">
                                <img class="logo" src="@/assets/images/logo_login.png" alt="">
                                <div class="text">Bine ai venit!</div>
                            </h5>
                            <form class="form" @submit="handleLogin">
                                <div class="fields">
                                    <v-text-field class="login-field" ref="identifier" v-model="credentials.identifier" :rules="[() => !!credentials.identifier || 'Camp obligatoriu']" placeholder="Email / Telefon" variant="solo" />
                                    <v-text-field class="rounded-lg" ref="password" v-model="credentials.password" type="password" :rules="[() => !!credentials.password || 'Camp obligatoriu']" placeholder="Parola" variant="solo" />
                                </div>
                                <div class="actions">
                                    <v-btn height="56" v-on:click="handleLogin" :elevation="0" :loading="loading" class="ml-auto w-100 login" color="primary" type="submit">LOGIN</v-btn>
                                </div>
                            </form>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style lang="scss">
@import 'LoginView.scss';
</style>
<script>
import { useAuthStore } from '@/stores/authStore.js';
import { mapStores } from 'pinia'
export default {
    name: "LoginView",
    data() {
        return {
            valid: true,
            loading: false,
            errror: null,
            response: null,
            credentials: {
                identifier: '',
                password: '',
            }
        }
    },
    computed: {
        ...mapStores(useAuthStore),
    },
    mounted() {

    },
    methods: {
        async handleLogin(e) {

            e.preventDefault();

            this.loading = true;
            this.valid = true;

            let keys = Object.keys(this.credentials);

            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                let errors = await this.$refs[key].validate(true);
                if (errors.length > 0) {
                    this.valid = false;
                }
            }

            if (this.valid === false) {
                this.$toast.error("Te rugam sa completezi toate campurile obligatorii");
                this.loading = false;
                return;
            }

            try {
                let user = await this.authStore.login(this.credentials);
                if (user) {
                    this.$router.push('/app/live');
                    this.$toast.success('Autentificare reusita');
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.data.human_message) {
                        error.response.data.human_message.forEach(message => {
                            this.$toast.error(message);
                        })
                    }
                }
                console.log('error', error);
            }
            this.loading = false;
        }
    }
}
</script>