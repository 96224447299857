import {defineStore} from 'pinia';

export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            auth_user: (localStorage.getItem('auth_user')) ? JSON.parse(localStorage.getItem('auth_user')) : null,
            auth_token: (localStorage.getItem('auth_token')) ? localStorage.getItem('auth_token') : null,
            auth_location: {
                lat: 44.426783,
                lng: 26.102449,
            },
            time_difference: 0,
        }
    },
    getters: {
        timeDifference(state) {
            if(state.time_difference === 0) {
                return null;
            }
            return state.time_difference.asSeconds();
        },
        loggedIn(state) {
            return (state.auth_user !== null);
        },
        user(state) {
            return (state.auth_user);
        },
        token(state) {
            return (state.auth_token);
        },
        userName(state) {
            return (state.auth_user?.firstname + " " + state.auth_user?.lastname)
        },
        location(state) {
            return state.auth_location;
        },
        canChooseFare(state) {
            return state.auth_user?.can_choose_fare || false;
        },
        address(state) {
            return (state.auth_user.address);
        },
        hasUserAddress(state) {
            return (state.loggedIn && typeof state.address !== 'undefined' && state.address !== null && state.user.level === 4);
        }
    },
    actions: {
        setTimeDifference(timeDifference) {
            this.time_difference = timeDifference;
        },
        setLocation(location) {
            this.auth_location = location;
        },
        async login(credentials) {
            let response = await axios.post('clients/login', credentials);

            this.auth_user = response.data.data;
            this.auth_token = response.data.data.api_token;

            axios.defaults.headers.common['api-token'] = this.auth_token;

            localStorage.setItem('auth_user', JSON.stringify(this.auth_user));
            localStorage.setItem('auth_token', this.auth_token);

            return this.auth_user;
        },
        logout() {
            this.auth_token = null;
            this.auth_user = null;
            localStorage.removeItem('auth_user');
            localStorage.removeItem('auth_token');
        },
        softLogout() {
            this.auth_token = null;
            this.auth_user = null;
        }
    },
});
