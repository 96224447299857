<template>
  <div class="view order" data-aos="fade-right">
    <!-- Desktop Layout -->
    <div class="live" :class="{
                'map-select-pickup': mapMode !== 'free',
                'has-orders': orderStore.hasOrders,
                'mobile-tab-order': mobileTab === 'order',
                'mobile-tab-orders': mobileTab === 'orders',
            }">
      <div class="mobile-navigation">
        <div class="items">
          <div @click="mobileTab = 'order'" class="item" :class="{'active': mobileTab === 'order'}">
            Comandă TAXI
          </div>
          <div @click="mobileTab = 'orders'" class="item" :class="{'active': mobileTab === 'orders'}">
            Comenzi Active
            <v-badge v-if="orderStore.hasOrders" text-color="#FFCE09" color="#363B4B"
                     :content="orderStore.orders.length" offset-y="-15" offset-x="-15"></v-badge>
          </div>
        </div>
      </div>
      <div class="order-zone custom-scroll">
        <order-form
            v-if="!errors"
            @errors="handleCreateOrderErrors"
            @clickMap="handleSelectFromMap"
            :info="info" ref="orderForm"
            @selectPickup="handleSelectPickup"
            @selectExtraDestination="handleSelectExtraDestination"
            @handleExtraDestinationChanged="handleExtraDestinationChanged"
            @orderCreated="mobileTab = 'orders'"/>
        <account-error @close="errors = null" :errors="errors" v-if="errors"/>
      </div>
      <div class="active-orders orders-zone custom-scroll">
        <active-order :order="order" v-for="order in orderStore.allOrders" :key="order.id"/>
      </div>
      <div class="map-zone" :class="mapMode" v-if="!useAuthStore().hasUserAddress">
        <v-card elevation="3">
          <GMapMap
              ref="mainMap"
              :center="{lat: center.lat, lng: center.lng}"
              :zoom="zoom"
              :options="{
                    mapTypeId: 'roadmap',
                    mapId: '4fe874960cd540c3',
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    minZoom: 11,
                    maxZoom: 19,
                }">
            <GMapMarker v-if="pickup" icon="/images/marker_pickup.svg"
                        :position="{lat: pickup.latitude, lng: pickup.longitude}" :draggable="true" :clickable="true"
                        @dragend="handlePickupDrag"/>
            <GMapMarker v-for="(v, index) in validDestinations" v-bind:icon="markerIcon(index)"
                        :position="{lat: v.place.latitude, lng: v.place.longitude}" :draggable="true" :clickable="true"
                        @dragend="handleExtraDestinationDrag($event, index)"/>
            <GMapPolyline ref="mapLine" v-if="info" :path="info.path"
                          v-bind:options="{ strokeColor:'#363B4B', strokeOpacity:0.8, strokeWeight: 3}"/>
          </GMapMap>
        </v-card>
        <div class="main-map-overlay" v-if="mapMode !== 'free'">
          <div class="close" @click="handleCloseSelectFromMap">
            <v-icon size="32px">mdi-close</v-icon>
          </div>
          <img class="center-icon" src="/images/marker_pickup.svg" alt="" v-if="mapMode === 'pickup'">
          <img class="center-icon" src="/images/marker_destination.png" alt="" v-if="mapMode !== 'pickup'">
        </div>
        <div class="confirm-pickup" v-if="mapMode !== 'free'">
          <div class="label">
            Alege locul de {{ (mapMode === 'pickup') ? 'preluare' : 'destinație' }}
          </div>
          <div class="address" v-if="tempPlace">
            {{ tempPlace.street_name }} {{ tempPlace.street_no }}
          </div>
          <div class="actions">
            <v-btn @click="confirmMapPoint" color="primary" class="submit-form" size="x-large" rounded="xl"
                   variant="flat">Confirmă
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import 'LiveView.scss';
</style>
<script>
import {useAuthStore} from '@/stores/authStore.js';
import {useOrderStore} from '@/stores/orderStore.js';
import {mapStores} from 'pinia';
import OrderForm from '@/components/live/OrderForm/OrderForm.vue';
import ActiveOrder from '@/components/live/ActiveOrder/ActiveOrder.vue';
import AccountError from '@/components/live/AccountError/AccountError.vue';
import starTaxiPlaces from '@/utils/starTaxiPlaces.js';
import socket from '@/emitters/socketBus.js';
import helper from '@/utils/helper.js';

if (typeof (Number.prototype.toRad) === "undefined") {
  Number.prototype.toRad = function () {
    return this * Math.PI / 180;
  }
}

//-- Define degrees function
if (typeof (Number.prototype.toDeg) === "undefined") {
  Number.prototype.toDeg = function () {
    return this * (180 / Math.PI);
  }
}

export default {
  name: 'OrderView',
  data() {
    return {
      errors: null,
      mobileTab: 'order',
      info: null,
      pickup: null,
      destination: null,
      extraDestinations: [],
      center: {
        lat: 44.426783,
        lng: 26.102449,
      },
      zoom: 15,
      mapMode: 'free',
      mapObject: null,
      tempPlace: null,
    }
  },
  computed: {
    ...mapStores(useAuthStore, useOrderStore),
    validDestinations: function () {
      return this.extraDestinations.filter(i => i.place && typeof i.place.latitude === 'number' && typeof i.place.longitude === 'number')
    }
  },
  watch: {
    mobileTab() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
  components: {
    OrderForm,
    ActiveOrder,
    AccountError,
  },
  async beforeUnmount() {
    socket.off('order-offer');
    socket.off('order-status-change');
  },
  async mounted() {

    this.center.lat = this.authStore.location.lat;
    this.center.lng = this.authStore.location.lng;

    await this.orderStore.fetchOrders();
  },
  methods: {
    useAuthStore,
    handleCreateOrderErrors(errors) {
      this.errors = errors;
    },
    markerIcon(index) {
      return (index < 9) ? `/images/0${index + 1}_pin.svg` : "/images/marker_destination.png";
    },
    centerMapMarkers() {
      const maps = window.google.maps;
      let b = new maps.LatLngBounds();
      b.extend(new maps.LatLng(this.pickup.latitude, this.pickup.longitude));
      this.validDestinations
          .forEach(a => b.extend(new maps.LatLng(a.place.latitude, a.place.longitude)));
      this.$refs.mainMap.fitBounds(b);
    },
    async confirmMapPoint() {
      if (this.mapMode === 'pickup') {
        this.$refs.orderForm.selectPickup(this.tempPlace);
      } else if (this.mapMode.type && this.mapMode.type === 'extraDestination') {
        this.$refs.orderForm.selectExtraDestination(this.tempPlace, this.mapMode.index);
      }
      this.mapMode = 'free';
      this.tempPlace = null;
    },
    handleCloseSelectFromMap() {
      this.mapMode = 'free';
      this.tempPlace = null;
    },
    async handleSelectFromMap(type) {
      if (this.mapObject == null) {
        this.mapObject = this.$refs.mainMap.$mapObject;
      }

      this.mapObject.addListener('dragend', this.handleMapCenterChange);

      this.tempPlace = await starTaxiPlaces.reverse({
        lat: this.mapObject.center.lat(),
        lng: this.mapObject.center.lng(),
      });
      this.mapMode = type;
    },
    async handleMapCenterChange() {
      if (this.mapMode === 'free') {
        return;
      }
      this.tempPlace = await starTaxiPlaces.reverse({
        lat: this.mapObject.center.lat(),
        lng: this.mapObject.center.lng(),
      });
    },
    // Pickup address changed
    async handleSelectPickup(location) {
      this.pickup = location;
      if (location) {
        this.center.lat = location.latitude;
        this.center.lng = location.longitude;
      }
      await this.getRideInfo2();
    },
    // Extra destination address changed
    async handleSelectExtraDestination(event) {
      this.extraDestinations[event.index] = {id: event.index, place: event.location};
      if (event.location) {
        this.center.lat = event.location.latitude;
        this.center.lng = event.location.longitude;
      }
      await this.getRideInfo2();
    },
    async handleExtraDestinationChanged(extraDestinations) {
      this.extraDestinations = extraDestinations;
      await this.getRideInfo2();
    },
    // Pickup map point change
    async handlePickupDrag(event) {
      let place = await starTaxiPlaces.reverse({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      })
      this.$refs.orderForm.selectPickup(place);
    },
    // Extra destination map point change
    async handleExtraDestinationDrag(event, index) {
      let place = await starTaxiPlaces.reverse({lat: event.latLng.lat(), lng: event.latLng.lng(),})
      this.$refs.orderForm.selectExtraDestination(place, index);
    },
    async getRideInfo2() {
      this.info = null;
      if (!this.pickup) {
        return;
      }

      let data = []
      data.push({'latitude': this.pickup.latitude, longitude: this.pickup.longitude})
      this.validDestinations
          .forEach(i => data.push({'latitude': i.place.latitude, longitude: i.place.longitude}))

      try {
        let response = await axios.post('clients/ride/info2', {waypoints: data});

        if (response.data.status !== true) {
          this.$toast.error("Eroare la incărcarea comenzilor.");
          return;
        }

        response.data.data.path = helper.decodePolyline(response.data.data.path);
        this.info = response.data.data;
        console.log(this.info);
        await this.$nextTick();
        this.centerMapMarkers();
      } catch (error) {
        console.error(error);
        this.$toast.error("Eroare la incărcarea comenzilor. 2");
      }

    },
  }
}
</script>
