<template>
    <div class="component chat-order-overlay" data-aos="fade">
        <div class="head">
            <div class="navigation">
                <v-row>
                    <v-col>
                        <img @click="$emit('back')" class="back" src="@/assets/images/arrow_back_thin.png" alt="">
                    </v-col>
                    <v-col>
                        <img class="picture" :src="order.offers[0].driver.profile_picture_url" alt="">
                    </v-col>
                </v-row>
            </div>
            <div class="address">
                <div class="pickup" v-if="order.order.order_details.address_id">
                    {{ order.order.order_details.address_id.street_name }} {{ order.order.order_details.address_id.street_number }}
                </div>
                <div class="to" v-if="order.order.order_details.destination_address">></div>
                <div class="destination" v-if="order.order.order_details.destination_address">
                    {{ order.order.order_details.destination_address.street_name }} {{ order.order.order_details.destination_address.street_number }}
                </div>
            </div>
        </div>
        <div class="chat">
            <div class="group" v-for="group in groupedMessages">
                <div class="timestamp">{{ $moment.format(group.timestamp, "hh:mm") }}</div>
                <div class="messages">
                    <div class="message" v-for="message in group.messages" :class="{'driver': message.sender_id == order.offers[0].driver.driver_id }">
                        <div class="body">{{ message.message_body }}</div>
                        <img class="picture" :src="order.offers[0].driver.profile_picture_url" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="type">
            <form @submit="sendMessage">
                <input type="text" class="message-input" v-model="message">
                <v-btn @click="sendMessage" class="main" variant="text">TRIMITE</v-btn>
            </form>
        </div>
    </div>
</template>
<style>
@import 'ChatOrderOverlay.scss';
</style>
<script>
import { useOrderStore } from '@/stores/orderStore.js';
import { mapStores } from 'pinia';
import moment from 'moment';
export default {
    name: "ChatOrderOverlay",
    props: ['order'],
    data() {
        return {
            message: '',
        }
    },
    watch: {
        'order.messages': {
            async handler(newMessages) {
                await this.$nextTick();
                $('.chat').scrollTop($(".chat")[0].scrollHeight);
            },
            deep: true,
        }
    },
    computed: {
        ...mapStores(useOrderStore),
        groupedMessages() {
            let messages = this.order.messages.map(e => {
                e.in_dts = moment(e.in_dts).format("YYYY-MM-DD hh:mm");
                return e;
            });
            let groups = [];
            for (var i = 0; i < messages.length; i++) {
                let message = messages[i];
                let existingGroup = groups.find(e => e.timestamp == message.in_dts);
                if (existingGroup) {
                    existingGroup.messages.push(message);
                } else {
                    let newGroup = {
                        timestamp: message.in_dts,
                        messages: [message],
                    }
                    groups.push(newGroup)
                }
            }
            return groups;
        },
    },
    mounted() {
        $('.chat').scrollTop($(".chat")[0].scrollHeight);
        $(".message-input").focus();
        this.readAll();
    },
    methods: {
        async sendMessage(e) {
            e.preventDefault();
            if (this.message.length < 1) {
                return;
            }
            await this.orderStore.sendMessage(this.order, this.message);
            this.message = '';
            $(".message-input").focus();
        },
        async readAll() {
            this.order.messages.forEach(message => {
                message.read = true;
            });
        }
    }
}
</script>