<template>
<div class="view subview drivers">
	<div class="title">
		Șoferi
	</div>
	<div class="page">
		<div class="sub-navigation">
			<div class="items">
				<router-link to="/app/account/drivers/favorites" class="nolink">
					<div class="item" :class="{'active': isActive('/app/account/drivers/favorites')}">
						Șoferi Favoriți
					</div>
				</router-link>
				<router-link to="/app/account/drivers/blacklisted" class="nolink">
					<div class="item" :class="{'active': isActive('/app/account/drivers/blacklisted')}">
						Șoferi Blocați
					</div>
				</router-link>
			</div>
		</div>
		<router-view /> 
	</div>
</div>
</template>
<style lang="scss">
@import 'DriversView.scss';
</style>
<script>
export default {
	name: "AccountDriversView",
	methods: {
		isActive(route) {
            return (this.$route.path.includes(route));
        }
	}

}
</script>