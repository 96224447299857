<template>
    <div class="view subview addresses">
        <div class="title">
            Adrese favorite
        </div>
        <div class="page">
            <div class="box box-add">
                <v-select hide-details v-model="currentType" class="field field-type" :items="types" variant="solo" return-object flat>
                    <template v-slot:item="{ props, item }">
                        <div class="icon" style="padding: 15px; cursor: pointer;" v-bind="props" v-ripple>
                            <img :src="item?.raw?.icon" alt="">
                        </div>
                    </template>
                    <template v-slot:selection="{ props, item }">
                        <img :src="item?.raw?.icon" alt="">
                    </template>
                </v-select>
                <autocomplete-address class="field field-address" ref="autocompleteAddress" @select="template.location = $event" label="Caută adresă" />
                <v-text-field class="field field-name" placeholder="Nume locație" hide-details ref="firstname" v-model="template.name" variant="solo" v-if="currentType.name == 'Favorite'" />
                <v-btn @click="addAddress" class="field field-add add main" variant="text" :disabled="(!template.location)">Adaugă</v-btn>
            </div>
            <!-- Home addresses -->
            <div class="addresses">                
                <div class="box address">
                    <div class="icon">
                        <img src="@/assets/images/favorites_home.png" alt="">
                    </div>
                    <div class="name">
                        Acasă
                    </div>
                    <div class="location"  v-if="homeAddress">
                        {{ homeAddress.street_name }} {{ homeAddress.street_no }}
                    </div>
                    <div class="location" v-else>
                        Lipsă adresă
                    </div>
                    <div class="actions"  v-if="homeAddress">
                        <img @click="selected = homeAddress; showDeleteModal = true;" src="@/assets/images/favorites_trash.png" alt="">
                    </div>
                </div>
            </div>
            <!-- Work Addresses -->
            <div class="addresses">                
                <div class="box address">
                    <div class="icon">
                        <img src="@/assets/images/favorites_work.png" alt="">
                    </div>
                    <div class="name">
                        Muncă
                    </div>
                    <div class="location"  v-if="workAddress">
                        {{ workAddress.street_name }} {{ workAddress.street_no }}
                    </div>
                    <div class="location" v-else>
                        Lipsă adresă
                    </div>
                    <div class="actions"  v-if="workAddress">
                        <img @click="selected = workAddress; showDeleteModal = true;" src="@/assets/images/favorites_trash.png" alt="">
                    </div>
                </div>
            </div>
            <div class="section-title">Alte locuri preferate</div>
            <div class="addresses" data-aos="fade" v-if="favoriteAddresses">
                <div class="box address" v-for="address in favoriteAddresses">
                    <div class="icon">
                        <img src="@/assets/images/favorites_star.png" alt="">
                    </div>
                    <div class="name">
                        {{ address.nickname || "Fără denumire" }}
                    </div>
                    <div class="location">
                        {{ address.street_name }} {{ address.street_no }}
                    </div>
                    <div class="actions">
                        <img @click="selected = address; showDeleteModal = true;" src="@/assets/images/favorites_trash.png" alt="">
                    </div>
                </div>
                <div class="box" v-if="favoriteAddresses.length == 0">
                    Nici o adresă favorită
                </div>
            </div>
            <div class="loading" v-else>
                <v-progress-circular :size="50" width="6" indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <v-dialog v-model="showDeleteModal" width="auto">
            <v-card class="deleteModal">
                <v-card-text>
                    <span>Ești sigur ca vrei sa ștergi din lista de favorite<br> această adresă?</span><br><br>
                    <img class="icon" src="@/assets/images/favorites_star.png" alt="" v-if="!['Work', 'Home'].includes(selected.nickname)"> 
                    <img class="icon" src="@/assets/images/favorites_home.png" alt="" v-if="selected.nickname == 'Home'"> 
                    <img class="icon" src="@/assets/images/favorites_work.png" alt="" v-if="selected.nickname == 'Work'"> 
                    {{ selected.street_name }} {{ selected.street_no }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="" variant="text" @click="showDeleteModal = false;">
                        Anulează
                    </v-btn>
                    <v-btn class="danger" variant="text" @click="removeAddress(selected); showDeleteModal = false">
                        Șterge
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style lang="scss">
@import 'AddressesView.scss';
</style>
<script>
import AutocompleteAddress from '@/components/forms/AutocompleteAddress/AutocompleteAddress.vue';
export default {
    name: "AccountAddressesView",
    data() {
        return {
            currentType: {
                name: 'Favorite',
                icon: require('@/assets/images/favorites_star.png')
            },
            types: [{
                    name: 'Favorite',
                    icon: require('@/assets/images/favorites_star.png')
                },
                {
                    name: 'Home',
                    icon: require('@/assets/images/favorites_home.png')
                },
                {
                    name: 'Work',
                    icon: require('@/assets/images/favorites_work.png')
                }
            ],
            selected: null,
            showDeleteModal: false,
            homeAddress: null,
            workAddress: null,
            favoriteAddresses: null,
            template: {
                type: null,
                name: null,
                location: null,
            }
        }
    },
    watch: {
        'currentType': {
            handler(newCurrentType) {
                if (newCurrentType.name == 'Favorite') {
                    this.template.name = '';
                } else {
                    this.template.name = newCurrentType.name;
                }
            },
            deep: true,
        }
    },
    components: {
        AutocompleteAddress,
    },
    async mounted() {
        await this.getAddresses();
    },
    methods: {
        async getAddresses() {
            try {

               
                let response = await axios.post('clients/get-addresses');
                if (response.data.status != true) {
                    this.$toast.error("Eroare la încărcarea datelor");
                    return;
                }

                this.favoriteAddresses = [];
                this.homeAddress = null;
                this.workAddress = null;

                for (var i = 0; i < response.data.data.favorites.length; i++) {
                    let address = response.data.data.favorites[i];
                        if(address.nickname == 'Home') {
                            this.homeAddress = address;
                        }
                        else if(address.nickname == 'Work') {
                            this.workAddress = address;
                        }
                        else {
                            this.favoriteAddresses.push(address);
                        }
                }

                this.addresses = response.data.data;
            } catch (error) {
                this.$toast.error("Eroare la încărcarea datelor");
            }
        },
        async addAddress() {
            try {

                console.log('location', this.template.location);

                this.template.location.nickname = this.template.name;
                if(this.template.name == 'Work' && this.workAddress) {
                    //this.template.id = this.workAddress.id;
                    await this.removeAddress(this.workAddress);
                }
                if(this.template.name == 'Home' && this.homeAddress) {
                    //this.template.id = this.homeAddress.id;
                    await this.removeAddress(this.homeAddress);
                }

                console.log('tpl', this.template.location);

                let response = await axios.post('clients/add-favorite-address', this.template.location);
                if (response.data.status == false) {
                    this.$toast.error("Eroare la încărcarea datelor");
                    return;
                }
                await this.getAddresses();
                this.$toast.success("Adresă adaugată");
                if(!['Home', 'Work'].includes(this.template.name)) {
                    
                }
                this.$refs.autocompleteAddress.selectAddress(null);
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare la încărcarea datelor");
            }
        },
        async removeAddress(address) {
            try {
                let response = await axios.post('clients/remove-favorite-addresses', {
                    address_id: {
                        0: address.address_id,
                    }
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                });
                if (response.data.status == false) {
                    this.$toast.error("Eroare la ștergerea adresei");
                    return;
                }
                await this.getAddresses();
                this.$toast.success("Adresa a fost ștearsă");
            } catch (error) {
                this.$toast.error("Eroare la ștergerea adresei");
            }
        }
    }
}
</script>