<template>
    <div class="component report-driver-overlay" data-aos="fade">
       <v-card class="order w-100" :class="order.order.order_details.status"  flat>
            <v-card-item>
                <v-card-title>
                    <div class="back"  @click="$emit('abort')" >
                        <img class="icon" src="@/assets/images/arrow_back_thin.png" alt="">
                        <div class="text">
                            Înapoi
                        </div>
                    </div>
                </v-card-title>
            </v-card-item>
            <v-card-text>
                <div class="title">Raportare Abuz</div>
                <v-textarea v-model="message" placeholder="Scrie un comentariu" variant="solo" :counter="160" :maxLength="250" flat></v-textarea>
                <div class="actions">
                    <v-btn @click="sendMessage" class="main" variant="text">TRIMITE</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>
<style>
@import 'ReportDriverOverlay.scss';
</style>
<script>
import { useOrderStore } from '@/stores/orderStore.js';
import { useAuthStore } from '@/stores/authStore.js';
import { mapStores } from 'pinia';
import moment from 'moment';
export default {
    name: "ReportDriverOverlay",
    props: ['order'],
    data() {
        return {
            message: '',
        }
    },
    watch: {
    },
    computed: {
        ...mapStores(useOrderStore, useAuthStore),
    },
    mounted() {
      
    },
    methods: {
        async sendMessage() {

            console.log(this.authStore.auth_user)
            console.log(this.order);

            if (this.message.length < 1) {
                this.$toast.error("Te rugăm să completezi mesajul")
                return;
            }

            try {
                let response = await axios.post('clients/report-abuse', {
                    user_id: this.authStore.auth_user.user_id,
                    driver_id: this.order.order.order_details.driver_id,
                    order_id: this.order.order.order_details.id,
                    message: this.message,
                });
                this.message = '';
                this.$toast.success("Abuz raportat");
                //this.$emit('confirm');
                this.orderStore.removeCachedOrder(this.order);


            } catch (error) {
                console.log(error);
                if (error.response && error.response.data) {
                    this.$toast.error(error.response.data.human_message[0]);
                } else {
                    this.$toast.error("Eroare la procesarea datelor");
                }
            }

        }
    }
}
</script>