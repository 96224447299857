<template>
  <div class="component app-navbar">
    <div class="navbar">
      <div class="logo">
        <router-link to="/app/live">
          <img src="@/assets/images/logo_header.png" alt="">
        </router-link>
      </div>
      <div class="navbar-menu d-none d-sm-inline-block">
        <router-link to="/app/live">
          <div class="navbar-item" :class="{ 'active': isActive('live') }">
            Comandă Taxi
            <div class="bar"></div>
          </div>
        </router-link>
        <router-link to="/app/history">
          <div class="navbar-item" :class="{ 'active': isActive('history') }">
            Istoric Comenzi
            <div class="bar"></div>
          </div>
        </router-link>
        <div @click="triggerPaymentMethodsModal" class="navbar-item">
          Metode Plata
          <div class="bar"></div>
        </div>
      </div>
      <div class="navbar-user">
        <v-menu offset="25">
          <template v-slot:activator="{ props }">
            <div v-bind="props">
              <span class="d-none d-sm-inline-block">{{ authStore.userName }}</span>
              <v-avatar color="#fff" size="30px">
                <v-img :src="userIcon" alt="John"></v-img>
              </v-avatar>
            </div>
          </template>
          <v-list elevation="1" :nav="true" density="compact">
            <v-list-item link to="/app/live">
              <v-list-item-title>Comandă taxi</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/app/history">
              <v-list-item-title>Istoric Comenzi</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/app/account">
              <v-list-item-title>Contul meu</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import 'Navbar.scss';
</style>
<script>
import {useAuthStore} from '@/stores/authStore.js';
import {mapStores} from 'pinia'
import eventBus from '@/emitters/eventBus.js';

export default {
  name: "AppNavbar",
  data() {
    return {
      userIcon: require("@/assets/images/user_icon.png"),
    }
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    async triggerPaymentMethodsModal() {
      await this.$router.push('/app/live');
      setTimeout(() => {
        eventBus.emit('triggerPaymentMethodsModal');
      }, 500);
    },
    async logout() {
      this.authStore.logout();
      this.$router.push('/login');
    },
    isActive(route) {
      return (this.$route.path.includes(route));
    }
  }
}
</script>
