<template>
<div class="view account">
	<div class="sidebar">
		<account-menu />
	</div>
	<div class="main-view">
		<div class="mobile-navigation d-block d-sm-none d-md-none">
			<account-menu :mobile="true" />	
		</div>
		<router-view data-aos="fade" />
	</div>
</div>
</template>
<style lang="scss">
@import "AccountView.scss";
</style>
<script>
import AccountMenu from '@/components/navigation/AccountMenu/AccountMenu.vue';
export default {
	name: "AccountView",
	components: {
		AccountMenu,
	}
}
</script>