<template>
<div class="view subview details">
	<div class="title">
		Ajutor
	</div>
	<div class="page">
		
	</div>
</div>
</template>
<script>
export default {
	
}
</script>