<template>
<div class="view subview details">
	<div class="title">
		Setări
	</div>
	<div class="page">
		<div class="box">
			<iframe class="" :srcdoc="data" v-if="data" style="width: 100%; height: 600px;" frameBorder="0">
			</iframe>
		</div>
	</div>
</div>
</template>
<script>
import { useAuthStore } from '@/stores/authStore.js';
import { mapStores } from 'pinia';
export default {
	data() {
		return {
			data: null,
		}
	},
	computed: {
        ...mapStores(useAuthStore),
    },
	async mounted() {
		await this.getSettings();
	},
	methods: {
		async getSettings() {
			let response = await axios.get(`https://api.startaxiapp.com/api/v2/webviews/privacy-settings?api_token=${this.authStore.token}&lang=ro`);
			this.data = response.data;

		}
	}
}
</script>
