<template>
  <div class="component order-form" data-aos="fade-right">
    <div class="title" v-if="!useAuthStore().hasUserAddress">{{ title }}</div>
    <div class="form">
      <v-row>
        <template v-if="!useAuthStore().hasUserAddress">
          <!-- Addresses -->
          <v-col cols="12">
            <autocomplete-address @focus="handleAddressFocus('pickup')" @clickMap="$emit('clickMap', $event)"
                                  @select="handleSelectPickup" :showMap="true" apiType="src" type="pickup"
                                  ref="pickupAddress" label="Caută adresă imbarcare"/>
          </v-col>

          <draggable tag="v-col" :component-data="{cols:12}" :list="extraDestinations" handle=".handle" item-key="id"
                     @change="handleExtraDestinationChanged">
            <template #item="{element, index}">
              <div class="extraDestinations">
                <font-awesome-icon size="lg" :icon="['fas', 'grip-vertical']" class="handle"
                                   v-visible="extraDestinations.length > 1" v-show="false"/>
                <!-- TODO: multiple destinations -->
                <autocomplete-address @focus="handleAddressFocus('destination')"
                                      @clickMap="$emit('clickMap', $event)"
                                      @select="handleSelectExtraDestination($event, index)"
                                      :showMap="true"
                                      apiType="dst"
                                      type="extraDestination"
                                      :extraDestinationIndex="index"
                                      :ref="'extraDestination_'+index"
                                      label="Caută adresă"
                />
                <div @click="removeExtraDestination(index)" class="remove" v-show="false">
                  <!-- TODO: multiple destinations -->
                  <v-icon>mdi-minus</v-icon>
                </div>
              </div>
            </template>
          </draggable>

          <v-col cols="12" v-show="false"> <!-- TODO: multiple destinations -->
            <button class="btn btn-secondary button add-destination" @click="addExtraDestination"
                    v-if="extraDestinations.length < maxDestinations">
              <v-icon>mdi-plus</v-icon>
            </button>
          </v-col>
        </template>

        <v-col cols="6">
          <v-text-field v-model="order.alternate_client_name" hide-details placeholder="Nume pasager"
                        variant="solo"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="order.alternate_phonenumber" hide-details placeholder="Număr telefon"
                        variant="solo"></v-text-field>
        </v-col>
        <!-- <v-col cols="12">
            <v-text-field v-model="order.details" hide-details placeholder="Detalii cursă" variant="solo"></v-text-field>
        </v-col> -->
        <v-col cols="12">
          <v-text-field v-model="order.details" hide-details placeholder="Detalii pentru șofer"
                        variant="solo"></v-text-field>
        </v-col>
        <v-col cols="6">
          <select-payment-method @updated="handleSelectPaymentMethod"/>
        </v-col>
        <v-col cols="6">
          <v-select v-if="useAuthStore().canChooseFare" placeholder="Tarif" v-model="order.order_fare_type" hide-details
                    :items="fareOptions" item-value="id" variant="solo">
            <template v-slot:selection="{ props, item }">
              {{ item?.title }}
            </template>
          </v-select>
          <v-select v-else placeholder="Opțiuni" v-model="order.options" hide-details :items="allOptions"
                    item-value="id"
                    multiple variant="solo">
            <template v-slot:selection="{ props, item }">
              {{ item?.title.substring(0, 3) }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" v-if="info" data-aos="fade-in">
          <div class="info-box">
            <v-row>
              <v-col cols="2" class="icon">
                <img src="@/assets/images/car.png" alt="" style="width: 43px;">
              </v-col>
              <v-col class="distance">
                Distanță <span class="sbold">&nbsp; {{ (info.distance / 1000).toFixed(2) }} &nbsp;</span>
                <span class="light">km</span>
              </v-col>
              <v-col cols="4" class="price">
                <span class="sbold">{{ info.min }}-{{ info.max }}</span> <span class="light">RON</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn @click="sendOrder" :color="orderIsValid && orderStore.processingOrders < 3 ? 'primary' : 'dark'"
                 class="submit-form w-100" size="x-large" rounded="xl"
                 :variant="(orderIsValid === true) && orderStore.processingOrders < 3 ? 'flat' : 'outlined'"
                 :disabled="!orderIsValid || orderStore.processingOrders >= 3">Comandă Acum
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import 'OrderForm.scss';
</style>
<script>
import AutocompleteAddress from '@/components/forms/AutocompleteAddress/AutocompleteAddress.vue';
import SelectPaymentMethod from '@/components/forms/SelectPaymentMethod/SelectPaymentMethod.vue';
import {useOrderStore} from '@/stores/orderStore';
import {useAuthStore} from '@/stores/authStore.js';
import {mapStores} from 'pinia';
import draggable from "vuedraggable";

export default {
  props: ['info'],
  name: "OrderForm",
  components: {
    AutocompleteAddress,
    SelectPaymentMethod,
    draggable,
  },
  data() {
    return {
      maxDestinations: 3,
      title: 'Unde ești?',
      extraDestinations: [{id: 0, place: {}}],
      order: {
        alternate_client_name: null,
        alternate_phonenumber: null,
        driver_details: null,
        pickup: null,
        waypoints: [],
        destination: null,
        details: null,
        options: [],
        payment_method: null,
        order_fare_type: null,
      },
      allOptions: [{id: 1, title: "AC",}, {id: 3, title: "Bagaje",}, {id: 4, title: "Rovinietă"}],
      fareOptions: [{id: 0, title: "Toti soferii"}, {id: 270, title: "Max 2.70 lei"}
      ]
    }
  },
  computed: {
    orderIsValid() {
      return (this.order.pickup != null || useAuthStore().hasUserAddress);
    },
    ...mapStores(useOrderStore),
  },
  methods: {
    useAuthStore,
    async sendOrder() {
      try {
        if (useAuthStore().hasUserAddress) {
          this.order.pickup = useAuthStore().address
        }
        this.extraDestinations.forEach(a => this.order.waypoints.push(a.place))
        // this.order.waypoints = this.extraDestinations.map(a => Object.assign({},a.place));
        this.order.destination = this.order.waypoints.pop();

        await this.orderStore.create(this.order);
        this.$toast.success("Comandă a fost plasată");

        this.order.waypoints = [];
        this.order.alternate_client_name = null;
        this.order.alternate_phonenumber = null;
        this.order.driver_details = null;
        this.order.details = null;
        this.order.options = [];

        this.$emit('orderCreated');
      } catch (error) {
        console.log(error);
        this.$toast.error("Eroare plasare comandă");
        if (error.response.data.message.length > 0) {
          this.$emit('errors', error.response.data.message);
        }
      }
    },
    async handleAddressFocus(type) {
      this.title = type === 'pickup' ? 'Unde ești?' : 'Unde mergi?';
    },
    handleSelectPaymentMethod(method) {
      this.order.payment_method = method.type;
    },
    handleSelectPickup(place) {
      this.order.pickup = place;
      if (place && place.details) {
        this.order.details = place.details;
      } else {
        this.order.details = null;
      }
      this.$emit('selectPickup', place);
    },
    selectPickup(place) {
      this.order.pickup = place;
      this.$refs.pickupAddress.selectAddress(place);
    },
    handleSelectExtraDestination(place, index) {
      console.log('handleSelectExtraDestination', index, place)
      this.extraDestinations[index].place = place;
      this.$emit('selectExtraDestination', {location: place, index: index});
    },
    handleExtraDestinationChanged() {
      console.error('handleExtraDestinationChanged', this.extraDestinations);
      this.$emit('handleExtraDestinationChanged', this.extraDestinations);
    },
    async removeExtraDestination(index) {
      this.extraDestinations.splice(index, 1);
      this.handleExtraDestinationChanged()
    },
    selectExtraDestination(place, index) {
      this.extraDestinations[index].place = place;
      this.$refs['extraDestination_' + index].selectAddress(place);
    },
    addExtraDestination() {
      const id = this.extraDestinations.length;
      this.extraDestinations.push({id, place: {}});
    }
  }
}
</script>
